import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import expire from "../../utils/Expired";
import { useTheme } from "../../utils/Hooks";

function CheckExpired({ disable, setDisable }) {
    const { Tenant } = useTheme();
    const table = window.location.pathname.split("/")?.[3];

    useEffect(() => {
        const checkExpiration = () => {
            const params = new URLSearchParams(window.location.search);
            const timestamp = params.get("timestamp");
            if (!timestamp) return false;
            const currentTime = new Date().getTime();
            const expirationTime = parseInt(timestamp, 10) + 30 * 60 * 1000; // 30 minutes
            return currentTime > expirationTime;
        };

        const updateTimestamp = () => {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set("timestamp", new Date().getTime());
            window.history.replaceState(null, null, currentUrl.href);
        };

        if (checkExpiration()) {
            setDisable(true);
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const timestamp = params.get("timestamp");
        const checkTable = isRouteTable();
        if (!timestamp && table && checkTable) {
            updateTimestamp();
            return;
        }

        const interval = setInterval(() => {
            if (checkExpiration()) {
                setDisable(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [disable, setDisable, table]);

    const isRouteTable = () => {
        const nonRouteTables = ["styles", "cart", "product", "contactUs", "termsConditions", "aboutUs", "reservation", "place-order", "order-details", "plays", "offers", "recipes", "login", "register", "forgot-password", "reset-password-confirm", "profile", "my-orders"];
        return nonRouteTables.indexOf(table) === -1;
    };

    if (disable || Tenant?.is_expired) return <RouterProvider router={expire} />;
    return null;
}

export default CheckExpired;
