import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Text from "../components/common/Text";

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            const { pathname, origin } = window.location;
            const newPath = pathname.replace(/\/profile$/, "");
            window.location.href = `${origin}${newPath}`;
        }
        return Promise.reject(error);
    }
);

const getBaseUrl = () => {
    const [, tenant, branch] = window.location.pathname.split("/");
    return { baseUrl: `https://${tenant}.${process.env.REACT_APP_BASEURL}`, tenant, branch };
};

const params = {
    retry: 0,
};

const getLanguage = () => {
    return localStorage.getItem("lang") || "en";
};

const Fetch = (url, params) => {
    return axios.get(url, {
        params: {
            domain: window.location.origin,
            ...params,
        },
        headers: {
            ["Accept-Language"]: getLanguage(),
        },
    });
};

export const useGetMenu = () => {
    const name = "repoData";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

export const useGetStyle = () => {
    const name = "styleData";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/styles/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => (branch ? Fetch(url) : null), params);
    return { isLoading, error, data: data?.data };
};

export const useGetTermsConditions = () => {
    const name = "terms-conditions";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/terms-conditions/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

export const useGetContactPage = () => {
    const name = "contact-page";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/contact-page/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesCategory = () => {
    const name = "recipies-categories";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/categories/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesCategoriesById = (id) => {
    const name = "recipies";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/?category=${id}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesById = (id) => {
    const name = "onerecipies";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/${id}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetAds = () => {
    const name = "ads";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/popup-ads/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetProductHook = (id) => {
    const name = "Product";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/menus/products/${id}`;
    const { isLoading, error, data } = useQuery([name, id], () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

const PostReview = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/add-review/`;
    return axios.post(url, data);
};

export const usePostReview = () => {
    return useMutation(PostReview, {
        onSuccess: () => {
            toast.success("Review Added Successfully");
        },
        onError: ({ response }) => {
            toast.error(response?.data?.detail);
        },
    });
};

const GetCheckAvailability = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/reservations/check-dates/?guests_no=${data?.guests_no}&reserved_date=${data?.reserved_date}&reserved_time=${data?.reserved_time}&tenant_slug=${data?.tenant_slug}&branch_slug=${data?.branch_slug}`;
    return axios.get(url);
};

export const useGetCheckAvailability = () => {
    return useMutation(GetCheckAvailability, {
        onSuccess: (res) => {
            return res.data;
        },
        onError: (err) => {
            console.error(err);
        },
    });
};

const PostReservation = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/reservations/make-reservation/`;
    return axios.post(url, data);
};

export const usePostReservation = () => {
    return useMutation(PostReservation, {
        onSuccess: (res) => {
            toast.success(res?.data?.responseMessage);
        },
        onError: ({ response }) => {
            toast.error(response?.data?.detail);
        },
    });
};

const PostOnlineOrder = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/create-online-order/`;
    return axios.post(url, data);
};

export const usePostOnlineOrder = () => {
    return useMutation(PostOnlineOrder, {
        onSuccess: (res) => {
            res?.data?.responseMessage?.map((message) => toast.success(message));
        },
        onError: ({ response }) => {
            response?.data?.responseMessage?.map((message) => toast.error(message));
        },
    });
};

export const useGetOrder = (params) => {
    const name = "order-details";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/order-details/${params?.id}?tenant_slug=${params?.tenant}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url));
    return { isLoading, error, data: data?.data };
};

export const useGetAreas = (params) => {
    const name = "branches/areas";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/branches/areas/front/?tenant_slug=${params?.tenant}&branch_slug=${params?.branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url));
    return { isLoading, error, data: data?.data };
};

const PostCheckDistance = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/check-distance/`;
    return axios.post(url, data, {
        headers: {
            "Accept-Language": getLanguage(),
        },
    });
};

export const useCheckDistance = () => {
    return useMutation(PostCheckDistance);
};

const PostCheckout = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/checkout/`;
    return axios.post(url, data, {
        headers: {
            "Accept-Language": getLanguage(),
        },
    });
};

export const useCheckout = () => {
    return useMutation(PostCheckout);
};

export const useGetBranches = () => {
    const name = "branches";
    const { baseUrl, tenant } = getBaseUrl();
    const url = `${baseUrl}/tenants/branches/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url, { busisnessName: tenant }));
    return { isLoading, error, data: data?.data };
};

export const useGetSEO = () => {
    const name = "seoData";
    const { baseUrl, branch, tenant } = getBaseUrl();
    const url = `${baseUrl}/tenants/seo/?busisnessName=${tenant}`;
    const { isLoading, error, data } = useQuery(name, () => (branch ? Fetch(url) : null), params);
    return { isLoading, error, data: data?.data?.responseData };
};

const PostLogin = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/login/`;
    return axios.post(url, data);
};

export const usePostLogin = () => {
    return useMutation(PostLogin, {
        onSuccess: (res) => {
            localStorage.setItem("token", res?.data?.access);
        },
    });
};

export const useGetProfile = (loggedIn = true) => {
    if (!loggedIn) {
        return { isLoading: false, error: null, data: null };
    }

    const name = "profile";
    const { baseUrl, tenant } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/me/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url, { busisnessName: tenant }));
    return { isLoading, error, data: data?.data };
};

const PatchProfile = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/me/`;
    return axios.patch(url, data);
};

export const usePatchProfile = () => {
    const QueryClient = useQueryClient();
    return useMutation(PatchProfile, {
        onSuccess: () => {
            QueryClient.invalidateQueries("profile");
        },
    });
};

const PostRegister = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/register/`;
    return axios.post(url, data);
};

export const usePostRegister = () => {
    return useMutation(PostRegister, {
        onSuccess: (res) => {
            localStorage.setItem("token", res?.data?.responseData?.token);
            toast.success(<Text title={"User Created Successfully"} />);
        },
    });
};

const PostResetPassword = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/reset-password/`;
    return axios.post(url, data);
};

export const usePostResetPassword = () => {
    return useMutation(PostResetPassword, {
        onSuccess: () => {
            toast.success(<Text title={"Password reset email sent successfully"} />);
        },
    });
};

const ConfirmResetPassword = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/reset-password-confirm/`;
    return axios.post(url, data);
};

export const useConfirmResetPassword = () => {
    return useMutation(ConfirmResetPassword, {
        onSuccess: () => {
            toast.success(<Text title={"Password has been reset successfully"} />);
        },
    });
};

export const useGetOrders = () => {
    const name = "orders";
    const { baseUrl, tenant } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/my-orders/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url, { busisnessName: tenant }));
    return { isLoading, error, data: data?.data };
};

export const useGetOrderDetails = (id) => {
    const name = "order";
    const { baseUrl, tenant } = getBaseUrl();
    const url = `${baseUrl}/frontend/auth/my-orders/${id}/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url, { busisnessName: tenant }));
    return { isLoading, error, data: data?.data };
};
