import React, { useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmResetPassword } from "../utils/API";
import { Form } from "react-bootstrap";
import { getQueryParams } from "../utils/helpers";

function ForgetPasswordConfirm() {
    const params = getQueryParams();
    const searchParams = new URLSearchParams(params);

    const uid = searchParams.get("uid");
    const token = searchParams.get("token");

    const navigate = useNavigate();
    const { table, tenant, branch } = useParams();

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const { isLoading, mutate, error, isSuccess } = useConfirmResetPassword();
    const [formData, setFormData] = useState({
        new_password: "",
        confirm_password: "",
        uid,
        token,
    });
    const [errors, setErrors] = useState({
        new_password: false,
        confirm_password: false,
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
        setErrors((prev) => ({ ...prev, [id]: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { new_password, confirm_password } = formData;

        const newErrors = {
            new_password: !new_password.trim(),
            confirm_password: !confirm_password.trim() || confirm_password !== new_password,
        };

        if (Object.values(newErrors).some((field) => field)) {
            setErrors(newErrors);
            return;
        }

        mutate(formData, {
            onSuccess: async () => {
                const basePath = table ? `/${tenant}/${branch}/${table}/login` : `/${tenant}/${branch}/login`;
                await handleNavigation(basePath);
            },
        });
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        const params = new URLSearchParams(queryString);
        params.delete("uid");
        params.delete("token");
        const updatedQueryString = params.toString();
        navigate(basePath + (updatedQueryString ? `?${updatedQueryString}` : ""));
    };

    return (
        <Container title="Confirm Reset Password">
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-11 mx-auto align-self-center py-4">
                        <h2 className="mb-4">
                            <span className="text-secondary fw-light">
                                <Text title="ConfirmResetPassword" />
                            </span>
                            <br />
                            <Text title="yourAccount" />
                        </h2>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type={passwordVisible ? "text" : "password"} id="new_password" isInvalid={errors.new_password} value={formData.new_password} onChange={handleChange} placeholder="" autoComplete="new_password" />
                            <Form.Label htmlFor="new_password">
                                <Text title="newPassword" />
                            </Form.Label>
                            <button type="button" className="tooltip-btn text-muted" title="Show Password" onClick={togglePasswordVisibility}>
                                <i className={`bi bi-eye${passwordVisible ? "-slash" : ""}-fill fs-6 `}></i>
                            </button>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Password is required" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-floating mb-3">
                            <Form.Control type={passwordVisible ? "text" : "password"} id="confirm_password" isInvalid={errors.confirm_password} value={formData.confirm_password} onChange={handleChange} placeholder="" autoComplete="new_password" />
                            <Form.Label htmlFor="confirm_password">
                                <Text title="newPasswordConfirm" />
                            </Form.Label>
                            <button type="button" className="tooltip-btn text-muted" title="Show Password" onClick={togglePasswordVisibility}>
                                <i className={`bi bi-eye${passwordVisible ? "-slash" : ""}-fill fs-6`}></i>
                            </button>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Passwords must match" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <div className="col-12 d-grid">
                                {error?.response?.data?.responseMessage?.map((message) => (
                                    <p className="text-center text-danger" key={message}>
                                        {message}
                                    </p>
                                ))}
                                <button type="submit" className="btn btn-default btn-lg btn-rounded shadow-sm" disabled={isLoading || isSuccess}>
                                    <h6>{isLoading ? <Text title="loading..." /> : <Text title="ConfirmReset" />}</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default ForgetPasswordConfirm;
