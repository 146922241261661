import React, { useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePostResetPassword } from "../utils/API";
import { Form } from "react-bootstrap";
import { getQueryParams } from "../utils/helpers";

function ForgetPassword() {
    const { table, tenant, branch } = useParams();
    const navigate = useNavigate();
    const { isLoading, mutate, error, isSuccess } = usePostResetPassword();
    const [formData, setFormData] = useState({
        branch_slug: branch,
        email: "",
    });
    const [errors, setErrors] = useState({
        email: false,
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
        setErrors((prev) => ({ ...prev, [id]: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email } = formData;

        const newErrors = {
            email: !email.trim(),
        };
        if (Object.values(newErrors).some((field) => field)) {
            setErrors(newErrors);
            return;
        }

        mutate(formData);
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <Container title="Forget Password">
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-11 mx-auto align-self-center py-4">
                        <h2 className="mb-4">
                            <span className="text-secondary fw-light">
                                <Text title="ForgetPasswordOf" />
                            </span>
                            <br />
                            <Text title="yourAccount" />
                        </h2>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type="text" id="email" isInvalid={errors.email} value={formData.email} onChange={handleChange} placeholder="" disabled={isSuccess} />
                            <Form.Label htmlFor="email">
                                <Text title="email" />
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Email is required" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <div className="col-12 d-grid">
                                {error?.response?.data?.responseMessage?.map((message) => (
                                    <p className="text-center text-danger" key={message}>
                                        {message}
                                    </p>
                                ))}
                                <button type="submit" className="btn btn-default btn-lg btn-rounded shadow-sm" disabled={isLoading || isSuccess}>
                                    <h6>{isLoading ? <Text title="loading..." /> : <Text title="SendResetEmail" />}</h6>
                                </button>
                                <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}${"/login"}` : `/${tenant}/${branch}${"/login"}`)} className="btn btn-outline-secondary btn-rounded shadow-sm btn-lg my-4">
                                    <h6>
                                        <Text title="GoToLogin" />
                                    </h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default ForgetPassword;
