import React, { useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePostLogin } from "../utils/API";
import { Form } from "react-bootstrap";
import { getQueryParams } from "../utils/helpers";

function Login() {
    const navigate = useNavigate();
    const { table, tenant, branch } = useParams();

    const { isLoading, mutate, error } = usePostLogin();
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({ email: false, password: false });
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
        setErrors((prev) => ({ ...prev, [id]: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = formData;

        const newErrors = {
            email: !email.trim(),
            password: !password.trim(),
        };
        if (Object.values(newErrors).some((field) => field)) {
            setErrors(newErrors);
            return;
        }
        mutate(formData, {
            onSuccess: async () => {
                const basePath = table ? `/${tenant}/${branch}/${table}/` : `/${tenant}/${branch}/`;
                await handleNavigation(basePath);
            },
        });
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <Container title="Login">
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-11 mx-auto align-self-center py-4">
                        <h2 className="mb-4">
                            <span className="text-secondary fw-light">
                                <Text title="signInTo" />
                            </span>
                            <br />
                            <Text title="yourAccount" />
                        </h2>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type="text" id="email" isInvalid={errors.email} value={formData.email} onChange={handleChange} placeholder="" />
                            <Form.Label htmlFor="email">
                                <Text title="email" />
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Email is required" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type={passwordVisible ? "text" : "password"} id="password" isInvalid={errors.password} value={formData.password} onChange={handleChange} placeholder="" />
                            <Form.Label htmlFor="password">
                                <Text title="Password" />
                            </Form.Label>
                            <button type="button" className="tooltip-btn text-muted" title="Show Password" onClick={togglePasswordVisibility}>
                                <i className={`bi bi-eye${passwordVisible ? "-slash" : ""}-fill fs-6`}></i>
                            </button>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Password is required" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <p className="mb-3 text-end">
                            <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}${"/forgot-password"}` : `/${tenant}/${branch}${"/forgot-password"}`)}>
                                <Text title="forgotPassword" />
                            </Link>
                        </p>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <div className="col-12 d-grid">
                                {error?.response?.data?.responseMessage?.map((message) => (
                                    <p className="text-center text-danger" key={message}>
                                        {message}
                                    </p>
                                ))}
                                <button type="submit" className="btn btn-default btn-lg btn-rounded shadow-sm" disabled={isLoading}>
                                    <h6>{isLoading ? <Text title="loading..." /> : <Text title="signIn" />}</h6>
                                </button>

                                <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}${"/register"}` : `/${tenant}/${branch}${"/register"}`)} className="btn btn-outline-secondary btn-lg btn-rounded shadow-sm my-4">
                                    <h6>
                                        <Text title="CreateNew" />
                                    </h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default Login;
