import React, { useEffect, useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { useGetProfile, usePatchProfile } from "../utils/API";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { getQueryParams } from "../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../utils/Hooks";

function Profile() {
    const navigate = useNavigate();
    const { userLanguage } = useLanguage();
    const { table, tenant, branch } = useParams();

    const { data: profileData, isLoading: isLoadingProfile } = useGetProfile();
    const { isLoading, mutate, error } = usePatchProfile();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        avatar: "",
    });
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        mobile: false,
    });
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (profileData && !isDirty) {
            setFormData({
                name: profileData.name || "",
                email: profileData.email || "",
                mobile: profileData.mobile || "",
                avatar: profileData.avatar || "",
            });
        }
    }, [profileData, isDirty]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
        setErrors((prev) => ({ ...prev, [id]: false }));
        setIsDirty(true);
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                toast.error(<Text title={"File size should be less than 5MB"} />);
            } else if (!file.type.startsWith("image/")) {
                toast.error(<Text title={"Only image files are allowed"} />);
            } else {
                setFormData((prev) => ({ ...prev, avatar: file }));
                setIsDirty(true);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, mobile } = formData;

        const newErrors = {
            name: !name.trim(),
            email: !email.trim(),
            mobile: !mobile.trim(),
        };
        if (Object.values(newErrors).some((field) => field)) {
            setErrors(newErrors);
            return;
        }

        const updatedFormData = new FormData();
        updatedFormData.append("name", name);
        updatedFormData.append("email", email);
        updatedFormData.append("mobile", mobile);
        if (formData.avatar instanceof File) {
            updatedFormData.append("avatar", formData.avatar);
        }

        mutate(updatedFormData, {
            onSuccess: () => {
                toast.success(<Text title={"Profile Updated Successfully"} />);
                setIsDirty(false);
            },
            onError: (err) => {
                if (err?.response?.data?.responseMessage) {
                    err.response.data.responseMessage.forEach((message) => {
                        toast.error(message);
                    });
                }
            },
        });
    };

    const handleLogout = async () => {
        localStorage.removeItem("token");
        const basePath = table ? `/${tenant}/${branch}/${table}/` : `/${tenant}/${branch}/`;
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <Container title="Profile" isLoading={isLoadingProfile} viewBottomTab={true}>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-11 mx-auto align-self-center py-4">
                        <h2 className="mb-4">
                            <Text title="Profile Details" />
                        </h2>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type="text" id="name" isInvalid={errors.name} value={formData.name} onChange={handleChange} placeholder="" />
                            <Form.Label htmlFor="name">
                                <Text title="Name" />
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Name is required" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type="email" id="email" isInvalid={errors.email} value={formData.email} onChange={handleChange} placeholder="" disabled />
                            <Form.Label htmlFor="email">
                                <Text title="Email" />
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Email is required" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-floating mb-3">
                            <Form.Control type="tel" id="mobile" isInvalid={errors.mobile} value={formData.mobile} onChange={handleChange} placeholder="" />
                            <Form.Label htmlFor="mobile">
                                <Text title="Mobile" />
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                <Text title="Mobile is required" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="d-flex align-items-center">
                            {formData.avatar && (
                                <div className="text-center my-3 mx-2">
                                    <img src={formData.avatar instanceof File ? URL.createObjectURL(formData.avatar) : formData.avatar} alt="" className="img-thumbnail bg-light" style={{ maxWidth: "90px", maxHeight: "90px" }} />
                                </div>
                            )}
                            <Form.Group className="form-floating mb-3 w-100">
                                <Form.Control type="file" id="avatar" onChange={handleAvatarChange} placeholder="" accept="image/*" />
                                <Form.Label htmlFor="avatar">
                                    <Text title="Avatar" />
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <div className="col-12 d-grid">
                                {error?.response?.data?.responseMessage?.map((message) => (
                                    <p className="text-center text-danger" key={message}>
                                        {message}
                                    </p>
                                ))}
                                <button type="submit" className="btn btn-default btn-lg btn-rounded shadow-sm" disabled={isLoading}>
                                    <h6>{isLoading ? <Text title="loading..." /> : <Text title="Update Profile" />}</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <h2 className="mb-4">
                                <Text title="Account Info" />
                            </h2>
                            {profileData?.last_login && (
                                <div className="col-12">
                                    <p>
                                        <strong>
                                            <Text title="Last Login:" />
                                        </strong>{" "}
                                        {moment(profileData?.last_login).locale(userLanguage).format("DD-MM-YYYY hh:mm a")}
                                    </p>
                                </div>
                            )}
                            {profileData?.date_joined && (
                                <div className="col-12">
                                    <p>
                                        <strong>
                                            <Text title="Date Joined:" />
                                        </strong>{" "}
                                        {moment(profileData?.date_joined).locale(userLanguage).format("DD-MM-YYYY hh:mm a")}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row">
                            <div className="col-12 d-grid">
                                <button type="button" className="btn btn-danger btn-lg btn-rounded shadow-sm" onClick={handleLogout}>
                                    <h6>
                                        <Text title="Logout" />
                                    </h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default Profile;
