export function darken(amount, color) {
    // Ensure the color is in the format #RRGGBB
    color = color.charAt(0) === "#" ? color : `#${color}`;

    // Parse the color into its RGB components
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Darken each RGB component
    const darkenedR = Math.max(0, Math.round(r * (1 - amount)));
    const darkenedG = Math.max(0, Math.round(g * (1 - amount)));
    const darkenedB = Math.max(0, Math.round(b * (1 - amount)));

    // Convert the darkened components back to hex
    const darkenedColor = `#${darkenedR.toString(16).padStart(2, "0")}${darkenedG.toString(16).padStart(2, "0")}${darkenedB.toString(16).padStart(2, "0")}`;

    return darkenedColor;
}

// Custom lighten function
export function lighten(amount, color) {
    // Ensure the color is in the format #RRGGBB
    color = color.charAt(0) === "#" ? color : `#${color}`;

    // Parse the color into its RGB components
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Lighten each RGB component
    const lightenedR = Math.min(255, Math.round(r + (255 - r) * amount));
    const lightenedG = Math.min(255, Math.round(g + (255 - g) * amount));
    const lightenedB = Math.min(255, Math.round(b + (255 - b) * amount));

    // Convert the lightened components back to hex
    const lightenedColor = `#${lightenedR.toString(16).padStart(2, "0")}${lightenedG.toString(16).padStart(2, "0")}${lightenedB.toString(16).padStart(2, "0")}`;

    return lightenedColor;
}

export function applyCustomColor(color) {
    const baseColor = color;

    const lightStyles = `
      .theme-custom {
        /* radial gradient colors */
        --oneuiux-theme-color-grad-1: ${baseColor};
        --oneuiux-theme-color-grad-2: ${darken(0.1, baseColor)};
        --oneuiux-theme-color-grad-3: ${darken(0.2, baseColor)};
        /* color schemes */
        --oneuiux-theme-color: ${baseColor};
        --oneuiux-page-bg-1: #ffffff;
        --oneuiux-page-bg-2: #fafafa;
        --oneuiux-page-bg-3: #f8f8f8;
      }
    `;
    const darkStyles = `
      .dark-mode .theme-custom {
        /* radial gradient colors */
        --oneuiux-theme-color-grad-1: ${darken(0.1, baseColor)};
        --oneuiux-theme-color-grad-2: ${darken(0.3, baseColor)};
        --oneuiux-theme-color-grad-3: ${darken(0.4, baseColor)};
        /* color schemes */
        --oneuiux-theme-color: ${baseColor};
        --oneuiux-theme-link: ${lighten(0.2, baseColor)};
        --oneuiux-header-active: ${lighten(0.3, baseColor)};
        --oneuiux-footer: ${darken(0.1, baseColor)};
        --oneuiux-card-color: ${darken(0.4, baseColor)};
        --oneuiux-page-bg-1: ${darken(0.8, baseColor)};
        --oneuiux-page-bg-2: ${darken(0.7, baseColor)};
        --oneuiux-page-bg-3: ${darken(0.9, baseColor)};
      }
    `;
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    const combinedStyles = lightStyles + darkStyles;
    if (styleElement.styleSheet) {
        styleElement.styleSheet.cssText = combinedStyles;
    } else {
        styleElement.appendChild(document.createTextNode(combinedStyles));
    }
    document.head.appendChild(styleElement);
}

export function getQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const queryString = queryParams.toString();
    return queryString;
}

export const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    return Boolean(token);
};
