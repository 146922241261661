import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetProfile } from "../../utils/API";
import { getQueryParams, isLoggedIn } from "../../utils/helpers";
import Text from "./Text";

const ProfileMenu = () => {
    const { table, tenant, branch } = useParams();
    const { data: profile } = useGetProfile(isLoggedIn());
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        setShowMenu(false);
        localStorage.removeItem("token");
        const basePath = table ? `/${tenant}/${branch}/${table}/` : `/${tenant}/${branch}/`;
        handleNavigation(basePath);
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
        setShowMenu(false);
    };

    return (
        <div className="profile-menu-container position-relative">
            <Link to="#" onClick={() => (isLoggedIn() ? setShowMenu(true) : handleNavigation(table ? `/${tenant}/${branch}/${table}${"/login"}` : `/${tenant}/${branch}${"/login"}`))} className="btn btn-default btn-44 btn-rounded profile-btn ms-2 align-content-center">
                {profile?.avatar && isLoggedIn() ? <img src={profile?.avatar} alt="" className="rounded-circle btn-44 btn-light profile-btn" style={{ objectFit: "cover" }} /> : <i className="bi bi-person-circle"></i>}
            </Link>

            {showMenu && (
                <div ref={menuRef} className="submenu position-absolute bg-light border rounded p-2">
                    <ul className="list-unstyled m-0">
                        <li>
                            <button className="dropdown-item btn btn-link" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/profile` : `/${tenant}/${branch}/profile`)}>
                                <Text title="Update Profile" />
                            </button>
                        </li>
                        <hr className="my-2 text-dark" />
                        <li>
                            <button className="dropdown-item btn btn-link" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/my-orders` : `/${tenant}/${branch}/my-orders`)}>
                                <Text title="My Orders" />
                            </button>
                        </li>
                        <hr className="my-2 text-dark" />
                        <li>
                            <button className="dropdown-item btn btn-link text-danger" onClick={handleLogout}>
                                <Text title="Logout" />
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ProfileMenu;
