import React, { useEffect } from "react";

const ExecuteScript = ({ scriptContent }) => {
    useEffect(() => {
        if (scriptContent) {
            // Create a script element
            const scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";

            // Extract the script portion from the received content
            const scriptMatch = scriptContent.match(/<script>([\s\S]*?)<\/script>/);
            if (scriptMatch && scriptMatch[1]) {
                scriptElement.text = scriptMatch[1]; // Set the script content
                document.head.appendChild(scriptElement); // Append to the head
            }

            // Clean up the script when the component unmounts
            return () => {
                document.head.removeChild(scriptElement);
            };
        }
    }, [scriptContent]);

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: scriptContent.replace(/<script>[\s\S]*<\/script>/, ""), // Exclude script from innerHTML
            }}
        />
    );
};

export default ExecuteScript;
