import React, { useState } from "react";
import Container from "../components/common/Container";
import { useGetOrders } from "../utils/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import Text from "../components/common/Text";
import { getQueryParams } from "../utils/helpers";
import moment from "moment";
import { useLanguage } from "../utils/Hooks";

function MyOrders() {
    const navigate = useNavigate();
    const { userLanguage } = useLanguage();
    const { table, tenant, branch } = useParams();
    const { isLoading, data } = useGetOrders();
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const orders = data?.results || [];
    const totalPages = data?.total_pages || 1;

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <Container title="My Orders" isLoading={isLoading} viewBottomTab={true}>
            <div className="row mb-2">
                {orders.map((order) => (
                    <div className="col-12" key={order.id}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col align-self-center">
                                        <div className="d-flex justify-content-between align-items-center my-2">
                                            <small className="text-muted size-12">{order.type_text}</small>
                                            <small className="text-muted size-12">{order.branch_name}</small>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center my-1">
                                            <h5 className="mb-0">{order.order_no}</h5>
                                            <span className="size-12 text-end" style={{ maxWidth: "60%" }}>
                                                {moment(order.pickup_time).locale(userLanguage).format("DD-MM-YYYY - hh:mm a")}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center my-1">
                                            <strong className="size-12">{order.total_formatted}</strong>
                                            <span className="size-12">
                                                <span className={`${order?.is_paid ? "text-success" : "text-danger"}`}>{order?.is_paid ? <Text title={"Paid"} /> : <Text title={"Unpaid"} />}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}${"/my-orders/"}${order?.id}` : `/${tenant}/${branch}${"/my-orders/"}${order?.id}`)} className="size-10">
                                            <Text title="Details" /> <i className={`bi bi-chevron-${userLanguage === "en" ? `right` : "left"}`}></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {totalPages > 1 && (
                    <nav className="mt-3">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </Container>
    );
}

export default MyOrders;
